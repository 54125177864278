<template>
  <div>
    <PageHeader main-header="nav-bar.kitchen" />
    <v-tabs show-arrows class="sticky-el--menu">
      <v-tab
        :to="{ name: 'request-type', params: { type: 'cooking' } }"
        @click="$helper.scrollTop"
        >{{ $t('kitchen-request.cooking') }}</v-tab
      >
      <v-tab
        :to="{ name: 'request-type', params: { type: 'serving' } }"
        @click="$helper.scrollTop"
        >{{ $t('kitchen-request.serving') }}</v-tab
      >
      <v-tab
        :to="{ name: 'request-type', params: { type: 'cancelled' } }"
        @click="$helper.scrollTop"
        >{{ $t('kitchen-request.cancelled') }}</v-tab
      >
    </v-tabs>
    <router-view></router-view>
  </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'

export default {
  name: 'Kitchen',

  components: {
    PageHeader
  }
}
</script>

<style scoped></style>
